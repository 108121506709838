<template>
  <div>
    <div class="addwork">
      <validation-observer ref="rule">
        <b-row>
          <!--===================================Ô input thêm tên nhóm người dùng============================== -->
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <div class="addwork__item">
              <b-form-group>
                <label
                  for="basicInputName"
                >Tên nhóm người dùng <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  :name="' Name Position Job'"
                  rules="required"
                  :custom-messages="customNameVali"
                >
                  <b-form-input
                    id="basicInputName"
                    v-model=" nameValue "
                    :state="errors.length > 0 ? false:null"
                    value=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <!--======================================Ô input thêm mã nhóm người dùng============================== -->
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <div class="addwork__item">
              <b-form-group>
                <label
                  for="basicInputCode"
                >Mã nhóm người dùng <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  :name="' Code Position Job'"
                  rules="required"
                  :custom-messages="customCodeVali"
                >
                  <b-form-input
                    id="basicInputCode"
                    v-model="codeValue"
                    :state="errors.length > 0 ? false:null"
                    value=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <!--====================================Ô input thêm Mô tả nhóm người dùng============================== -->
          <b-col
            md="12"
            xl="12"
            class="mb-1"
          >
            <div>
              <label for="textarea-default">Mô tả nhóm người dùng</label>
              <b-form-textarea
                id="textarea-default"
                v-model="descriptionValue"
                placeholder=""
                rows="3"
                no-resize
              />
            </div>
          </b-col>
        </b-row>
        <div class="addwork__button">
          <!--=======================================Nút thêm nhóm người dùng=============================== -->
          <div class="addwork__button__item">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addUserGroupFC()"
            >
              Lưu lại
            </b-button>
          </div>
          <!--=====================Nút hủy bỏ nhóm người dùng============================== -->
          <div class="addwork__button__item">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="close()"
            >
              Hủy bỏ
            </b-button>
          </div>
        </div>
      </validation-observer></div></div>

</template>

<script>import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import { required, email, max } from '@validations'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customCodeVali: {
        required: 'Mã nhóm người dùng là bắt buộc',
      },
      customNameVali: {
        required: 'Tên nhóm người dùng là bắt buộc',
      },
      nameValue: '',
      codeValue: '',
      descriptionValue: '',
    }
  },
  async created() {
    if (this.$route.params.id) {
      await this.fetchUserGroupToId(this.$route.params.id)
      this.nameValue = this.dataEditUserGroup[0].name
      this.codeValue = this.dataEditUserGroup[0].code
      this.descriptionValue = this.dataEditUserGroup[0].description
    }
  },
  computed: {
    ...mapState('userGroup', ['alertAddUserGroup', 'dataEditUserGroup', 'alertAddUserGroup']),

  },
  methods: {
    ...mapActions('userGroup', ['addUserGroup', 'checkCodeUserGroup', 'checkNameUserGroup', 'addJobAction', 'editUserGroup', 'fetchUserGroupToId']),
    ...mapMutations('userGroup', ['ALERT_ADD_USER_GROUP']),

    //= =================== funtion thêm vị trí công việc ========================//
    async addUserGroupFC() {
      if (this.$route.params.id) {
        this.$refs.rule.validate().then(async success => {
          if (success) {
            if (this.nameValue !== this.dataEditUserGroup[0].name) {
              // eslint-disable-next-line no-const-assign
              this.checkName = await this.checkNameUserGroup(this.nameValue)
            } else {
              this.checkName = false
            }
            if (this.codeValue !== this.dataEditUserGroup[0].code) {
              // eslint-disable-next-line no-const-assign
              this.checkCode = await this.checkCodeUserGroup(this.codeValue)
            } else {
              this.checkCode = false
            }
            if (this.checkName === false && this.checkCode === false) {
              const dataEdit = {
                code: this.codeValue,
                description: this.descriptionValue,
                name: this.nameValue,
                // eslint-disable-next-line comma-dangle
                id: this.dataEditUserGroup[0].id
              }
              await this.editUserGroup(dataEdit)
              await this.$router.push('/User-Group')
            }
            // eslint-disable-next-line no-constant-condition
            await this.showToast(this.alertAddUserGroup === 'Sửa Thành Công!' ? 'success' : 'danger', this.alertAddUserGroup)
          }
        })
      } else {
        this.$refs.rule.validate().then(async success => {
          if (success) {
            const checkName = await this.checkNameUserGroup(this.nameValue)
            const checkCode = await this.checkCodeUserGroup(this.codeValue)
            if (checkCode === false && checkName === false) {
              const dataAdd = {
                code: this.codeValue,
                description: this.descriptionValue,
                name: this.nameValue,
              }
              await this.addUserGroup(dataAdd)
              await this.$router.push('/User-Group')
            }
            // eslint-disable-next-line no-constant-condition
            await this.showToast(this.alertAddUserGroup === 'Thêm Thành Công!' ? 'success' : 'danger', this.alertAddUserGroup)
          }
        })
      }
    },
    // ==================addUserGroup=========
    close() {
      this.$router.push('/User-Group')
    },
    //= ================= Funtion hiện thông báo ======================//
    showToast(variant, nameAlert) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text: nameAlert,
          variant,
        },
      })
    },
  },
}
</script>

<style>
/* .addwork{
  background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;
padding:20px;
}
.addwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
}
.addwork__button__item{
  margin-left:20px
}

.addwork__alert{
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  width: 100%;
  height: 40px;
  background-color: rgb(252,234,234);
  margin-bottom:10px;
  border-radius:  5px;
}
.addwork__alert p{
  margin: 0px;
  padding: 0px;
  color: rgb(234,84,85);
} */
</style>
